<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-v-component
      heading="Not Recently Serviced"
    />
    <v-dialog
      v-model="showErrorDialog"
      persistent
      max-width="600"
      @keydown.esc="showErrorDialog = false"
    >
      <error-message
        :error="error"
        @closeErrorDialog="closeErrorDialog"
      />
    </v-dialog>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          {{ loadingMessage }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="tooManyRecords"
      persistent
      max-width="500"
    >
      <v-card class="pl-2 pr-2">
        <v-card-title class="headline">
          Large Number of Records
        </v-card-title>
        <v-row>
          <v-col>
            <v-alert
              type="success"
              icon="mdi-email"
            >
              Only a sample of record is being shown because there's too many records to retrieve at once. <br><br>
              <strong>Would you like the complete report to be emailed to you?</strong>
            </v-alert>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="emailReport"
          >
            Yes
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="tooManyRecords = false"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-alert
      color="blue-grey"
      dark
      dense
      icon="mdi-school"
      prominent
    >
      This report lets you see which customers have not been in your service department recently. Before we can find which customers haven't returned, we need to first determine which customers that we want to look at.
      <br><br>
      The date range controls which customers that we look at to see if they have returned recently. So the further back you go, the more customers will likely be included.
      <br>
      The slider lets you specify how many days it has been since the customer has been in the service department.
    </v-alert>
    <v-row>
      <v-col cols="12">
        <v-stepper v-model="wizard">
          <v-stepper-header>
            <v-stepper-step
              :complete="wizard > 1"
              step="1"
            >
              Select Date Ranges
            </v-stepper-step>
            <v-divider />
            <v-stepper-step
              :complete="wizard > 1"
              step="2"
            >
              Select Campaign
            </v-stepper-step>
            <v-divider />
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card
                color="lighten-1"
              >
                <h2
                  style="font-weight: normal"
                  v-html="message()"
                />
                <v-card-text>
                  <v-row
                    align="center"
                    justify="center"
                  >
                    <v-col
                      class="xs"
                      sm="6"
                      md="4"
                      lg="2"
                      xl="1"
                    >
                      <v-menu
                        v-model="start_date_menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="formattedStartDate"
                            label="Start Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="start_date"
                          @input="start_date_menu = false"
                        />
                      </v-menu>
                    </v-col>
                    <v-col>
                      <v-select
                        v-model="daysSinceLastServiced"
                        :items="days_list"
                        label="Select the number of days since last serviced"
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="daysSinceLastServiced"
                        label="Or manually type in the number of days if it's not in the list"
                      />
                    </v-col>
                    <v-col>
                      <v-combobox
                        v-model="selected_makes"
                        :items="all_makes"
                        label="Select the makes you want to filter by"
                        multiple
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-combobox
                        v-model="selectedHeaders"
                        :items="allHeaders"
                        label="Select the columns you want to appear"
                        multiple
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    @click="wizard = 2"
                  >
                    Next
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-card
                color="lighten-1"
              >
                <v-row dense>
                  <v-col>
                    <v-select
                      v-model="campaignId"
                      :items="campaigns"
                      label="Campaigns"
                      outlined
                      item-text="name"
                      item-value="id"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-text-field
                      v-model="fileDescription"
                      label="File Description"
                    />
                  </v-col>
                </v-row>
              </v-card>
              <v-btn
                color="primary"
                @click="loadCustomers"
              >
                Finish
              </v-btn>
              <v-btn
                text
                @click="wizard = 1"
              >
                Back
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
    <template v-if="items.length > 0">
      <v-row>
        <template v-if="items.length > 0">
          <v-col>
            <h2>
              {{ formatNumber(items.length) }} Records
            </h2>
          </v-col>
        </template>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-col class="text-right">
              <download-excel
                :data="parsedItems"
                type="csv"
                :name="fileName"
              >
                <v-icon
                  large
                  color="green darken-2"
                  v-on="on"
                >
                  mdi-microsoft-excel
                </v-icon>
              </download-excel>
            </v-col>
          </template>
          <span>Download</span>
        </v-tooltip>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
            </v-card-title>
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="selectedHeaders"
              :items="items"
              :search="search"
              :items-per-page="50"
              :sort-by="['last_serviced_at']"
              item-key="customer_number"
            >
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-card>
                    <v-card-title
                      class="headline"
                    >
                      More info about {{ item.year }}
                    </v-card-title>
                  </v-card>
                </td>
              </template>
              <template #item.sold_at="{value}">
                {{ formatDate(value) }}
              </template>
              <template #item.last_serviced_at="{value}">
                {{ formatDate(value) }}
              </template>
              <template #item.phone="{value}">
                {{ formatPhoneNumber(value) }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>
<script>
  import NetworkService from '@/services/NetworkService.js'
  import ErrorMessage from '@/components/ErrorMessage.vue'
  import MailCampaignService from '@/services/MailCampaignService'
  import DmsReportingService from '@/services/DmsReportingService.js'
  import { mapGetters } from 'vuex'
  import { pick } from '@/mixins/exportMixin.js'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import { serviceMixin } from '@/mixins/serviceMixin.js'
  import Vue from 'vue'

  export default {
    name: 'DmsSalesList',
    components: {
      ErrorMessage,
    },
    mixins: [formatterMixin, serviceMixin],
    data () {
      return {
        reportType: 'not_recently_serviced',
        cacheKey: null,
        tooManyRecords: false,
        start_date: this.defaultStartDate(),
        end_date: this.defaultEndDate(),
        start_date_menu: false,
        end_date_menu: false,
        items: [],
        search: '',
        loading: false,
        showErrorDialog: false,
        error: '',
        wizard: 1,
        expanded: [],
        singleExpand: false,
        daysSinceLastServiced: 180,
        all_makes: [],
        selected_makes: [],
        all_models: [],
        selected_models: [],
        all_years: [],
        selected_years: [],
        filter_by_makes_switch: false,
        filter_by_models_switch: false,
        filter_by_years_switch: false,
        loadingMessage: 'Loading',
        days_list: [{ text: '3 Months', value: '90' }, { text: '6 Months', value: '180' }, { text: '9 Months', value: '270' }, { text: '1 Year', value: '360' }],
        campaignId: null,
        fileDescription: 'Not Recently Serviced',
        campaigns: [],
        allHeaders: [
          { text: 'Customer Number', value: 'customer_number' },
          { text: 'First Name', value: 'first_name', align: 'center' },
          { text: 'Last Name', value: 'last_name', align: 'center' },
          { text: 'Address', value: 'address_1', align: 'center' },
          { text: 'Address 2', value: 'address_2', align: 'center' },
          { text: 'City', value: 'city', align: 'center' },
          { text: 'Zip', value: 'zip', align: 'center' },
          { text: 'Phone', value: 'phone', align: 'center' },
          { text: 'Email', value: 'email', align: 'center' },
          { text: 'Last Service Date', value: 'last_serviced_at', align: 'center' },
          { text: 'Date Sold', value: 'sold_at', align: 'center' },
          { text: 'Address Validated?', value: 'validated', align: 'center' },
          { text: 'Year', value: 'year', align: 'center' },
          { text: 'Make', value: 'make', align: 'center' },
          { text: 'Model', value: 'model', align: 'center' },
          { text: 'VIN', value: 'vin', align: 'center' },
        ],
        selectedHeaders: [
          { text: 'Customer Number', value: 'customer_number' },
          { text: 'First Name', value: 'first_name', align: 'center' },
          { text: 'Last Name', value: 'last_name', align: 'center' },
          { text: 'Address 1', value: 'address_1', align: 'center' },
          { text: 'Address 2', value: 'address_2', align: 'center' },
          { text: 'City', value: 'city', align: 'center' },
          { text: 'State', value: 'state', align: 'center' },
          { text: 'Zip', value: 'zip', align: 'center' },
          { text: 'Phone', value: 'phone', align: 'center' },
          { text: 'Email', value: 'email', align: 'center' },
          { text: 'Last Service Date', value: 'last_serviced_at', align: 'center' },
        ],
      }
    },
    computed: {
      formattedStartDate () {
        return this.formatDate(this.start_date)
      },
      formattedEndDate () {
        return this.formatDate(this.end_date)
      },
      parsedItems () {
        const headers = this.selectedHeaders.map(h => h.value)
        if (this.items) {
          return this.items.map(i => pick(i, headers))
        }
        return []
      },
      fileName () {
        return `${this.accountName
          .toLowerCase()
          .replace(' ', '_')}_not_recently_serviced.xls`
      },
      ...mapGetters(['accountId', 'accountName', 'reportData', 'user']),
    },
    watch: {
      accountId () {
        this.loadCustomers()
        this.loadCampaigns()
      },
      reportData () {
        this.loadingMessage = 'Rendering Report'
        if (this.reportData.reportType === this.reportType) {
          this.cacheKey = this.reportData.cacheKey
          this.items = []
          this.loading = true
          DmsReportingService.retrieveReport(this.reportData.cacheKey, this.reportData.campaignId, 'Not recently serviced', this.selectedHeaders.map(h => h.value), this.reportType, this.reportData.fileName)
            .then(response => {
              if (response.data.report) {
                this.items = response.data.report.items
                this.tooManyRecords = response.data.too_many_records
              } else {
                this.items = []
              }
              this.loading = false
            })
            .catch(error => {
              this.loading = false
              this.error = NetworkService.getErrorMessage(error)
              this.showErrorDialog = true
            })
        }
      },
    },
    created () {
      this.getMakes()
      this.getModels()
      this.getYears()
      this.loadCampaigns()
    },

    methods: {
      closeErrorDialog: function () {
        this.showErrorDialog = false
      },
      loadCustomers: function () {
        this.wizard = 1
        this.items = []
        this.loading = true
        Vue.set(this.user, 'report_data', {})
        this.items = []
        this.loadingMessage = 'Contacting Server'
        var data = {
          startDate: this.start_date,
          endDate: this.end_date,
          daysSinceLastServiced: this.daysSinceLastServiced,
        }
        data.campaignId = this.campaignId
        data.headerValues = this.selectedHeaders.map(h => h.value)
        data.fileDescription = this.fileDescription
        data.makes = this.selected_makes

        DmsReportingService.notRecentlyServiced(data)
          .then(response => {
            this.loadingMessage = 'Running Report'
            if (response.data.items) {
              this.items = response.data.items
            } else {
              this.items = []
            }
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      emailReport: function () {
        this.loading = true
        var data = {
          cacheKey: this.reportData.fileName,
          headerValues: this.selectedHeaders.map(h => encodeURIComponent(h.value)),
        }

        DmsReportingService.emailReport(data)
          .then(response => {
            this.tooManyRecords = false
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      loadCampaigns: function () {
        this.campaignId = null
        this.campaigns = []
        this.loading = true
        MailCampaignService.getMailCampaigns()
          .then(response => {
            this.campaigns = response.data
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      message: function () {
        var message = 'Show me customers that have been in the service department since <strong>' + this.formattedStartDate + '</strong>, '
        if ((this.selected_years.length > 0 && this.filter_by_years_switch) || (this.selected_makes.length > 0) || (this.selected_models.length > 0 && this.filter_by_models_switch)) {
          message = message + ' with vehicles '
          if (this.selected_years.length > 0 && this.filter_by_years_switch) {
            message = message + 'from <strong>' + this.selected_years + '</strong>, '
          }
          if (this.selected_makes.length > 0) {
            message = message + 'with makes of <strong>' + this.selected_makes + '</strong>, '
          }
          if (this.selected_models.length > 0 && this.filter_by_models_switch) {
            message = message + 'with models of <strong>' + this.selected_models + '</strong>, '
          }
        }
        message = message + 'but have not been serviced in the past <strong>' + this.daysSinceLastServiced + '</strong> days.'

        return message
      },
      defaultStartDate () {
        return new Date(new Date().setDate(new Date().getDate() - 720)).toISOString().substring(0, 10)
      },
      defaultEndDate () {
        return new Date(new Date().setDate(new Date().getDate())).toISOString().substring(0, 10)
      },
    },
  }
</script>
