import DmsService from '@/services/DmsService.js'
import NetworkService from '@/services/NetworkService.js'
export const serviceMixin = {
  methods: {
    getMakes: function () {
      this.loading = true
      DmsService.getServiceMakes()
        .then(response => {
          if (response.data.makes) {
            this.all_makes = response.data.makes
          } else {
            this.all_makes = []
          }
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          this.error = NetworkService.getErrorMessage(error)
          this.showErrorDialog = true
        })
    },
    getModels: function () {
      this.loading = true
      DmsService.getServiceModels()
        .then(response => {
          if (response.data.models) {
            this.all_models = response.data.models
          } else {
            this.all_models = []
          }
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          this.error = NetworkService.getErrorMessage(error)
          this.showErrorDialog = true
        })
    },
    getYears: function () {
      this.loading = true
      DmsService.getServiceYears()
        .then(response => {
          if (response.data.years) {
            this.all_years = response.data.years
          } else {
            this.all_years = []
          }
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          this.error = NetworkService.getErrorMessage(error)
          this.showErrorDialog = true
        })

    },
    getStates: function () {
      this.loading = true
      DmsService.getServiceStates()
        .then(response => {
          if (response.data.states) {
            this.all_states = response.data.states
          } else {
            this.all_states = []
          }
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          this.error = NetworkService.getErrorMessage(error)
          this.showErrorDialog = true
        })
    },
  },
}
